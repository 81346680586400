
    <ul class="nav nav-tabs search-selector" role="tablist">
      <li *ngFor="let search of searchTypes" [ngClass]="{ 'active': isActive(search)}">
        <a
          [routerLink]="search.link"
          [queryParams]="search.params"
          class="search-filter"
          >{{ search.label }}</a>
      </li>
    </ul>
  