
  <section class="now-playlist ux-maker">
    <div *ngIf="isPlaylistEmpty" class="empty-list text-center" [@flyOut]>
      <icon name="music" class="bg-primary ux-maker"></icon>
      <article>
        <h3 class="text-primary">Playlist Is Empty</h3>
        <p class="text-primary">Queue Media From Results</p>
      </article>
    </div>
    <ul class="nav nav-list ux-maker nicer-ux" cdkDropList 
      (cdkDropListDropped)="onTrackDrop($event)"
      [cdkDropListLockAxis]="'y'">
      <li class="now-playlist-track" #playlistTrack cdkDrag
        *ngFor="let video of playlist.videos | search:playlist.filter; let index = index"
        [class.active]="isActiveMedia(video.id, playlistTrack)"
        [@flyOut]>
        <now-playlist-track
          [video]="video" [index]="index"
          (remove)="removeVideo($event)"
          (select)="selectVideo(video)"
          (selectTrack)="selectTrackInVideo($event)"
        ></now-playlist-track>
      </li>
    </ul>
  </section>
  