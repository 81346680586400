<section
  class="is-flex-column is-flex-valign bg-danger"
  *ngIf="errorShow$ | async">
  <article class="is-flex-row is-flex-valign">
    <h4 class="is-strechable">
      <icon name="info-circle"></icon> Something Went Wrong
    </h4>
    <button-icon
      (click)="close()"
      icon="close"
      types="primary"></button-icon>
  </article>
  <article class="text-left">{{ errorMessage$ | async }}</article>
  <div class="is-flex-row">
  <ng-container *ngIf="errorAction$ | async as errorAction">
    <button
      class="btn btn-default"
      (click)="handleAction(errorAction)">
      {{ errorAction }}
    </button>
    <button
      class="btn btn-default"
      title="(!) This clears out now playlists, search and theme selection (!)"
      (click)="reset()">
      Reset App
    </button>
  </ng-container>
</div>
</section>
