<app-player class="navbar navbar-default navbar-fixed-bottom"></app-player>

<app-sidebar [class.closed]="sidebarCollapsed$ | async"></app-sidebar>


<div class="container-main">
  <router-outlet></router-outlet>
</div>

<app-error-handler></app-error-handler>
<add-to-playlist></add-to-playlist>
