
    <article
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollDisabled]="currentPlaylist$ | async"
      (scrolled)="searchMore()"
      [immediateCheck]="true"
    >
      <app-navbar>
        <div class="navbar-header">
          <player-search
            [query]="query$ | async"
            [searchParams]="queryParams$ | async"
            (queryChange)="resetPageToken($event)"
            (paramsChange)="updateParams($event)"
            (search)="search($event)"
          ></player-search>
        </div>
        <section class="is-flex-row is-content-aligned-h">
          <search-navigator
            [searchType]="searchType$ | async"
            [queryParams]="queryParams$ | async"
          ></search-navigator>
        </section>
      </app-navbar>
      <router-outlet></router-outlet>
    </article>
  