
    <a class="btn btn-link navbar-link navbar-btn"
      *ngIf="signedIn; else userNotSignedIn"
      [routerLink]="['/user']">
      <img [src]="userImageUrl" class="user-icon">
    </a>
    <ng-template #userNotSignedIn>
      <button class="btn btn-link navbar-link navbar-btn is-flex-row is-flex-valign"
        title="Sign In With Your TouTube account"
        (click)="handleSignIn()">
        <span class="sign-in-text">Sign In</span>
        <div class="sign-in-with-youtube"></div>
    </button>
    </ng-template>
  