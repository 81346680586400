
    <button class="btn btn-navbar btn-transparent ux-maker btn-toggle"
      (click)="toggleMenu()">
      <icon name="ellipsis-v"></icon>
      <icon *ngIf="appVersion.isNewAvailable" name="dot-circle-o" class="pulse update-indicator text-primary"></icon>
    </button>
    <div class="menu-backdrop" *ngIf="!hide" (click)="hideMenu()"></div>
    <div class="panel menu-dropdown"
      [class.end-animation]="end"
      [@expandFadeIn]="menuState"
      (@expandFadeIn.done)="endAnimation($event)"
      >
      <div class="list-group">
        <div *ngIf="appVersion.isNewAvailable" class="list-group-item">
          <button class="btn btn-success" title="click to update Echoes"
            (click)="handleVersionUpdate()">
            New Version Is Available - UPDATE NOW
          </button>
        </div>
        <a class="list-group-item" href="https://github.com/orizens/echoes-player" target="_blank" rel="noopener">
          <icon name="github" prefix="fa"></icon> Source Code @Github
        </a>
        <a class="list-group-item" href="/assets/privacy-policy.txt" target="_blank">
          <icon name="shield"></icon> Privacy Policy
        </a>
        <a class="list-group-item" *ngIf="!hide" href="https://travis-ci.org/orizens/echoes-player" target="_blank" rel="noopener">
          <icon name="medkit"></icon> <img src="https://travis-ci.org/orizens/echoes-player.svg?branch=master">
        </a>
        <div class="list-group-item menu-version" target="_blank">
          <section>
            <icon name="code-fork"></icon>
            <a href="https://github.com/orizens/echoes-player/blob/master/CHANGELOG.md" target="_blank" rel="noopener">
              {{ appVersion.semver }}
            </a>
          </section>
          <button *ngIf="!appVersion.isNewAvailable"
          class="btn btn-info" (click)="handleVersionCheck()">
          Check For Updates
          </button>
          <div *ngIf="appVersion.checkingForVersion" class="text-info">
          checking for version...
          </div>
        </div>
        <div class="list-group-item theme-selector">
          <icon name="paint-brush" class="text-primary"></icon> Theme: 
          <button-group [buttons]="theme.themes" [selectedButton]="theme.selected"
            (buttonClick)="updateTheme($event)"></button-group>
        </div>
        <a class="list-group-item" href="http://orizens.com" rel="noopener" blank="_target">
          <icon name="keyboard-o"></icon> Made with <icon name="heart-o" class="text-danger"></icon> By Orizens
        </a>
        <a class="list-group-item navbar-action-link" href="https://docs.google.com/forms/d/e/1FAIpQLSdzGRIXoHuzRFZU03EyhgwBJgJp6W1LMatz6Bn44L-5SyuxZA/viewform" target="_blank" rel="noopener">
          <icon name="star-o"></icon> Request New Features
        </a>
        <a class="list-group-item navbar-action-link" href="https://echoesplayer.netlify.app/homepage/index.html" target="_blank" rel="noopener">
          <icon name="star-o"></icon> Home Page
        </a>
        <button class="list-group-item"
          *ngIf="signedIn"
          (click)="handleSignOut()">
          <icon name="sign-out"></icon> Sign Out
        </button>
      </div>
    </div>
  