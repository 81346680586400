<section
  class="youtube-item card ux-maker"
  [class.show-description]="showDesc">
  <section class="media-card">

    <div class="front face is-rounded-bottom">
      <div class="indicators clearfix">

        <span class="pull-left item-is-playing">
					<icon name="play"></icon>Now Playing
				</span>

      </div>

      <div
        rel="tooltip"
        class="media-thumb is-rounded-top"
        [title]="media.snippet.title"
        (click)="playVideo(media)">

        <div class="thumbnail">
          <img
            name
            class="thumb-image thumb-shadow"
            [src]="media | videoToThumb">
          <img
            name
            class="thumb-image is-rounded"
            [src]="media | videoToThumb">
        </div>

        <button class="btn btn-transparent text-primary btn-lg ux-maker play-media">
          <icon name="youtube-play 3x"></icon>
        </button>
      </div>

      <section class="item-actions main-actions is-rounded-bottom">
        <h4 class="title">
					<span
            href="#/video/"
            rel="tooltip"
            class="media-thumb ellipsis">
            {{ media.snippet.title }}
          </span>
        </h4>
        <!-- <section class="stats is-absolute-bottom is-flex-row">
          <span
            class="item-likes item-action"
            rel="tooltip"
            title="Likes">
						<icon name="thumbs-up"></icon> {{ media.statistics?.likeCount | number:'2.0'}}
					</span>

          <span
            class="item-views item-action"
            rel="tooltip"
            title="Views">
						<icon name="eye"></icon> {{ media.statistics?.viewCount | number:'2.0'}}
					</span>
        </section> -->
        <section class="media-actions is-flex-row is-flex-valign">
          <button
            *ngIf="!queued"
            class="btn btn-transparent text-info first-action"
            (click)="queueVideo(media)">
            <icon name="reply" title="Queue this video to now playlist"></icon>
          </button>
          <button
            *ngIf="queued"
            class="btn btn-transparent text-danger no-padding"
            (click)="removeVideoFromQueue(media)">
            <icon name="trash" title="Queue this video to now playlist"></icon>
          </button>
          <button
            class="btn text-primary btn-transparent no-padding"
            (click)="addVideoToPlaylist(media)">
            <icon name="plus" title="Add This video to a playlist"></icon>
          </button>
          <!-- <button
            class="btn btn-link add-to-playlist"
            disabled
            title="Add this video to a playlist"
            (click)="addVideo(media)">
            <icon name="plus"></icon>
            Add
          </button> -->
          <!-- <span class="is-strechable"></span> -->
          <span class="text-info item-duration">
						<icon name="clock-o"></icon> {{ media.contentDetails?.duration | toFriendlyDuration }}
					</span>
          <button class="btn btn-transparent no-padding">
            <icon
              name="info-circle"
              title="more info about this video"
              class="text-info text-md is-media-valign"
              (click)="toggle(showDesc)"
              ></icon>
          </button>
        </section>
      </section>
    </div>

    <div class="description back face is-rounded is-absolute-top is-absolute-left">
      <h4>
				<a
          href="https://youtube.com/watch?v={{ media.id }}"
          target="_blank"
          rel="noopener"
          title="Open in YouTube"
          class="media-thumb"><icon
            name="youtube"
            [prefix]="ICON_PREFIX_BRAND"></icon>
          {{ media.snippet.title}}
        </a>
			</h4>
      <div *ngIf="showDesc">{{ media.snippet.description }}</div>
    </div>

    <section
      *ngIf="showDesc"
      class="close-desc is-absolute-bottom is-absolute-right">
      <button
        (click)="toggle(showDesc)"
        class="btn-transparent btn-xs text-md text-success"
        title="flip back...">
        <icon name="times-circle"></icon>
      </button>
    </section>

  </section>

</section>
